@tailwind base;
@tailwind components;
@tailwind utilities;
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #000; /* Tailwind color: scrollbar-track */
}

::-webkit-scrollbar-thumb {
  background-color: #f1dbf1; /* Tailwind color: scrollbar-thumb */
  border-radius: 4px;
  border: 2px solid #cbd5e0; /* Match the track color */
}

::-webkit-scrollbar-thumb:hover {
  background-color: yellow; /* Darker color on hover */
}

/* Firefox Scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #cbd5e0; /* Thumb color, track color */
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom animations */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 2s ease-out;
}

.animate-slide-in {
  animation: slide-in 1s ease-out;
}

.animate-bounce {
  animation: bounce 2s infinite;
}
